import React, { useState } from "react"

import { LandingScreen } from "../Home/Styled"
import Currency_hires from "../../images/Currency_hires.jpg"
import Approvals_hires from "../../images/Approvals_hires.jpg"
import home_mobile from "../../images/home_mobile.jpg"
import Partner_Access_hires from "../../images/Partner_Access_hires.jpg"
import Custom_Function_hires from "../../images/Custom_Function_hires.jpg"
import OpportunityPerspectives_hires from "../../images/OpportunityPerspectives_hires.jpg"
import Dashboard_Consolidated_view_of_Enterprise_highres from "../../images/Dashboard_Consolidated_view_of_Enterprise_highres.jpg"

import Performance_individual_hires from "../../images/Performance_individual_hires.jpg"
import Opportunity_hires from "../../images/Opportunity_hires.jpg"

import Configurable_fiscal_period_hires from "../../images/Configurable_fiscal_period_hires.jpg"

import { Collapse, Button, CardBody, Card } from "reactstrap"

// import img6 from "../../images/MultiFunction.jpg"

import SalesProcess_high_res from "../../images/SalesProcess_high_res.jpg"
import img8 from "../../images/TaskGovernance.jpg"

import Escalation_hires from "../../images/Escalation_hires.jpg"

// import MultiStagePipeline from "../../images/MultiStagePipeline.jpg"
import sales2 from "../../images/sales2.jpg"

// import Partner from "../../images/Partner.jpg"
import security2 from "../../images/security1.png"

import { Link } from "gatsby"
import feature1 from "../../images/Feature1.jpg"
import feature2 from "../../images/Feature2.jpg"
import feature3 from "../../images/Feature3.jpg"
import feature4 from "../../images/Feature4.jpg"
import feature5 from "../../images/Feature5.jpg"
import feature6 from "../../images/Feature6.jpg"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { loginUrl } from "../../config/keys"
// import {
//   Carousel,
//   CarouselItem,
//   CarouselControl,
//   CarouselIndicators,
// } from "reactstrap"
import "./Features.css"
import Signup from "../Home/signup"
import ScrollToTop from "./ScrollToTop"
import outofboxperformance from "../../images/outofboxperformance.jpg"
import CARDIMG1 from "../../images/outofbox.jpg"
import CARDIMG2 from "../../images/CustomerjourneyNav.jpg"
import CARDIMG3 from "../../images/flowchart.jpg"
import CARDIMG4 from "../../images/Opportunities.jpg"
import CARDIMG5 from "../../images/TeamWork.jpg"
import CARDIMG6 from "../../images/Security.jpg"
import { Features1 } from "../../components/UI"
import perspective from "../../images/perspective.jpg"
import CustomerMap_highres from "../../images/CustomerMap_highres.jpg"
import Dashboard_highres from "../../images/Dashboard_highres.jpg"
import Contact_Mobile_highres from "../../images/Contact_Mobile_highres.jpg"
import Event_Add_highres from "../../images/Event_Add_highres.jpg"
import leads from "../../images/leads.jpg"
import quotation from "../../images/quotations.jpg"
import { CaretUp } from "@react-icons/all-files/fa/FACaretUp"

// import clsx from "clsx"

function Homepage() {
  const [isOpen, setIsOpen] = useState(false)

  function toggle() {
    setIsOpen(!isOpen)
  }
  const [isOpen1, setIsOpen1] = useState(false)

  function toggle1() {
    setIsOpen1(!isOpen1)
  }
  const [isOpen3, setIsOpen3] = useState(false)
  function toggle3() {
    console.log("toggle3")
    setIsOpen3(!isOpen3)
  }
  const [isOpen4, setIsOpen4] = useState(false)
  function toggle4() {
    console.log("toggle3")
    setIsOpen4(!isOpen4)
  }

  const [isOpen5, setIsOpen5] = useState(false)
  function toggle5() {
    setIsOpen5(!isOpen5)
  }
  const [isOpen6, setIsOpen6] = useState(false)
  function toggle6() {
    setIsOpen6(!isOpen6)
  }
  const [isOpen7, setIsOpen7] = useState(false)
  function toggle7() {
    setIsOpen7(!isOpen7)
  }
  const [isOpen8, setIsOpen8] = useState(false)
  function toggle8() {
    setIsOpen8(!isOpen8)
  }

  // const [activeIndex, setActiveIndex] = useState(0)
  // const items = [
  //   {
  //     title:
  //       "Key metrics in rich visualization and real time to give your sales team a jumpstart",
  //   },
  //   {
  //     title:
  //       "Simple and intuitive user interface, ready to use for an enterprise",
  //   },
  //   {
  //     title: "Never lose sight of your customers using our sales automation ",
  //   },
  //   {
  //     title:
  //       "We enrich information about your customer for you to close opportunity",
  //   },
  //   {
  //     title: "Simple process to customize your sales process",
  //   },
  // ]

  // function next() {
  //   const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
  //   setActiveIndex(nextIndex)
  // }

  // function previous() {
  //   const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
  //   setActiveIndex(nextIndex)
  // }

  // function goToIndex(newIndex) {
  //   setActiveIndex(newIndex)
  // }
  // const slides = items.map((item) => (
  //   <CarouselItem>
  //     <div className="top-left22" style={{ top: "100px" }}>
  //       <h1>{item.title}</h1>
  //     </div>
  //   </CarouselItem>
  // ))
  return (
    <div>
      <div className="menu">
        <div className="megamenushow">
          <div
            className="mega-menu"
            style={{
              marginTop: "40px",
              marginLeft: "100px",
              marginRight: "100px",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              <div
                style={{
                  width: "30%",

                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <div style={{ width: "25%" }}>
                  <img src={CARDIMG1} style={{ padding: "10px" }} alt="img1" />
                </div>
                <div style={{ width: "75%" }}>
                  <Link to="/outoftheboxuse/" style={{ color: "white" }}>
                    <span className="feature-dropdown-header ">
                      Out-of-the-box Use
                    </span>
                  </Link>
                  <div className="feature-dropdown-content">
                    Get started in minutes
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "30%",

                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <div style={{ width: "25%" }}>
                  <img
                    src={CARDIMG2}
                    alt="img2"
                    style={{ padding: "10px", height: "82px" }}
                  />
                </div>
                <div style={{ width: "75%" }}>
                  <Link to="/customerjourney/" style={{ color: "white" }}>
                    <span className="feature-dropdown-header ">
                      Customer journey
                    </span>
                  </Link>
                  <div className="feature-dropdown-content">
                    Opportunity life cycle integrated with workflows and tasks
                    for better and faster results
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "30%",

                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <div style={{ width: "25%" }}>
                  <img
                    src={CARDIMG3}
                    alt="img3"
                    style={{ padding: "10px", height: "82px" }}
                  />
                </div>
                <div style={{ width: "75%" }}>
                  <Link to="/automation/" style={{ color: "white" }}>
                    <span className="feature-dropdown-header ">
                      Automation - value to business
                    </span>
                  </Link>
                  <div className="feature-dropdown-content">
                    Easy configuration of processes to improve productivity
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              <div
                style={{
                  width: "30%",

                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <div style={{ width: "25%" }}>
                  <img
                    src={CARDIMG4}
                    alt="img4"
                    style={{ padding: "10px", height: "82px" }}
                  />
                </div>
                <div style={{ width: "75%" }}>
                  <Link
                    to="/leadsopportunitiesandclosures/"
                    style={{ color: "white" }}
                  >
                    <span className="feature-dropdown-header ">
                      Leads to Closures
                    </span>
                  </Link>
                  <div className="feature-dropdown-content">
                    Perspectives and tile representation for better User
                    experience
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "30%",

                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <div style={{ width: "25%" }}>
                  <img
                    src={CARDIMG5}
                    alt="img5"
                    style={{ padding: "10px", height: "82px" }}
                  />
                </div>
                <div style={{ width: "75%" }}>
                  <Link
                    to="/collaboratebreakthesilos/"
                    style={{ color: "white" }}
                  >
                    <span className="feature-dropdown-header ">
                      Collaborate "Break the silos"
                    </span>
                  </Link>
                  <div className="feature-dropdown-content">
                    Include users from outside sales functions for faster
                    closures and improved governance
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "30%",
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <div style={{ width: "25%" }}>
                  <img
                    src={CARDIMG6}
                    alt="img6"
                    style={{ padding: "10px", height: "82px" }}
                  />
                </div>
                <div style={{ width: "75%" }}>
                  {/* <Link to="/securityandaccess/" style={{ color: "white" }}>
                    <span className="feature-dropdown-header ">
                      Security and Access
                    </span>
                  </Link> */}
                  <div className="feature-dropdown-content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile_text">
        <div style={{ textAlign: "justify" }}>
          <span style={{ color: "rgb(0, 191, 255)" }}>SALES AUTOMATION</span>{" "}
          features enable you to never lose sight of your customers, leverage to
          improve on closure rates.
          <br />
        </div>
        <div style={{ textAlign: "justify", marginTop: "6px" }}>
          <span style={{ color: "rgb(0, 191, 255)" }}>KEY METRICS</span> in rich
          visualization and{" "}
          <span style={{ color: "rgb(0, 191, 255)" }}>REAL TIME</span> for a
          jumpstart.
        </div>
        <div style={{ textAlign: "justify", marginTop: "6px" }}>
          Simple and intuitive user interface,{" "}
          <span style={{ color: "rgb(0, 191, 255)" }}>
            READY TO USE OUT OF THE BOX
          </span>{" "}
          for an enterprise.
        </div>
      </div>
      <LandingScreen mobileBackgroundImage={`url(${home_mobile})`}>
        <div className="landing_image_main_box">
          <div className="landing_image_main_box_first_child">
            <div className="image_text_For_landing_page">
              <span style={{ color: "white" }}></span>
            </div>
            <div className="button_for_landing_page_in_web">
              <a
                onClick={(e) => {
                  trackCustomEvent({
                    category: "start your free trial caraousel button ",

                    action: "Click",

                    label: "Start your free trial button in home page",

                    value: 43,
                  })
                }}
                className="banner_buttons"
                href={`${loginUrl}/register/Option`}
                target="_blank"
              >
                START YOUR 30 DAYS FREE TRIAL NOW!
              </a>
            </div>
          </div>
        </div>
      </LandingScreen>

      <div className="">
        <div className="landing-button_forMobile_feature">
          <a
            onClick={(e) => {
              trackCustomEvent({
                category: "start your free trial caraousel button ",

                action: "Click",

                label: "Start your free trial button in home page",

                value: 43,
              })
            }}
            className="banner_buttons"
            href={`${loginUrl}/register/Option`}
            target="_blank"
          >
            START YOUR 30 DAYS FREE TRIAL NOW!
          </a>
        </div>
        {/* First Box */}

        {/* <div style={{border:"1px solid red"}}</div> */}

        <div className="feature1" id="1">
          <h5>Out-of-the-box Use</h5>
        </div>
        <div className="feature-left-box-content1">
          <h1>Get Set, Ready, Sell</h1>
          <br /> <br />
        </div>

        <div className="main ">
          <div className="feature-right ">
            <img src={feature1} alt="bginh" />
          </div>

          <div className="feature-left">
            {/* <div className="spacer"></div> */}

            <p className="feature-left-content">
              Swing into action with pre-configured processes built-in
              analytiacal tools and a wide range of extensive features and
              create immediate results. Empower your teams to sell faster,
              manage oppertunities better, and increase win rates <br />
              <br />
              Real time analytics and reporting help focus on customer
              engagement, automation improves productivity and customer
              acquisition.
            </p>
          </div>
        </div>

        <div style={{ marginTop: "50px" }}></div>
        {/* POINT1 WEB*/}
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Performance_individual_hires}
                alt="Pre-configured indicators and metrics so that you can focus on your business and
                 close more opportunities, measure effectiveness of your efforts easily."
                className="border-image"
              />
            </div>
          </div>

          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h1>Just a few clicks to do the tricks</h1>
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img
                  src={Performance_individual_hires}
                  alt="Pre-configured indicators and metrics so that you can focus on your business and close more opportunities, 
                  measure effectiveness of your efforts easily."
                  className="border-image"
                />
              </div>
            </div>

            <p className="feature-left-box-content">
              Leverage our out of box featurs to hit the ground running, easily
              onboard your people and set of processes. gain visibility across
              your business and start creating immediate results from day 1.{" "}
              <br />
              <br />
              KPIs, Sales processes, automations- Generate and mail quotations,
              notifications, dashboards and more <br />
              <br />
              Richly visualised dashboard with pre configured metrics presents
              data in real time, no more waiting for reports <br />
              <br />
              Real-time analytics, reporting to enhance customer engagement and
              conversions <br />
              <br />
            </p>
          </div>
        </div>

        {/* POINT1 MOBILE */}
        <div className="feature-main-box_mobile ">
          <div className="feature-right-box ">
            <div className="feature-image_onMobile">
              <img
                src={Performance_individual_hires}
                alt="Pre-configured indicators and metrics so that you can focus
                 on your business and close more opportunities, measure effectiveness of your efforts easily."
                className="border-image"
              />
            </div>
          </div>

          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Pre configured Key Performance Indicators</h2>
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img
                  src={Performance_individual_hires}
                  alt="Pre-configured indicators and metrics so that you can focus on your business
                   and close more opportunities, measure effectiveness of your efforts easily."
                  className="border-image"
                />
              </div>
            </div>

            <p className="feature-left-box-content">
              Richly visualized dashboard with an extremely user-friendly
              interface and pre configured metrics presents data in real time,
              whenever you want, no more waiting for the monthly or quarterly
              reports. <br />
              <br />
              <div onClick={toggle3} style={{ color: "#2cc9ff" }}>
                {isOpen1 ? "Show Less" : "Show More"}
                {/* <CaretUp /> */}
              </div>
              <Collapse isOpen={isOpen3}>
                Measure effectiveness of your effort and customer engagement,
                track opportunity closure. <br />
                <br />
                Pre-configured indicators and metrics in real time help you
                focus on improving sales funnel quality and close more
                opportunities.
              </Collapse>
            </p>
          </div>
        </div>

        {/* 
For Web  */}

        <div className="spacer-gap"></div>

        {/* POINT1 FOR WEB */}
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h1>Start Selling from the get go! </h1>
            </div>

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={Opportunity_hires}
                  alt="Configure additional sales processes easily, no IT support needed"
                  className="border-image"
                />
              </div>
            </div>

            <p className="feature-left-box-content">
              Get started is easy, Simply Drag and drop to manage your
              oppertunities, see progress in the sales process. Start creating
              new oppertunities and take your business to new heights. <br />
              <br />
              Gain insights on each customer for better engagement and improved
              with rates.
              <br />
              <br />
              Pre-configured Sales Processes & KPIs and metrics <br />
              <br />
              Focus more on the oppertunity and closure leave the administrative
              task to Korero <br />
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Opportunity_hires}
                alt="Opportunity_hires"
                className="border-image"
              />
            </div>
          </div>
        </div>

        {/* for mobile */}

        <div className="feature-main-box_mobile ">
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Start Selling from the get go!</h2>
            </div>

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img
                  src={Dashboard_Consolidated_view_of_Enterprise_highres}
                  alt="Configure additional sales processes easily, no IT support needed."
                  className="border-image"
                />
              </div>
            </div>

            <p className="feature-left-box-content">
              Get started is easy, Simply Drag and drop to manage your
              oppertunities, see progress in the sales process. Start creating
              new oppertunities and take your business to new heights.
              <div className="spacer"></div>
              <div onClick={toggle} style={{ color: "#2cc9ff" }}>
                {isOpen ? "Show Less" : "Show More"}
                {/* <CaretUp /> */}
              </div>
              {/* <Button color="primary" onClick={toggle} style={{ marginBottom: '1rem' }}></Button> */}
              <Collapse isOpen={isOpen}>
                Gain insights on each customer for better engagement and
                improved with rates. <br />
                <br />
                Pre-configured Sales Processes & KPIs and metrics <br />
                <br />
                Focus more on the oppertunity and closure leave the
                administrative task to Korero <br />
                <br />
              </Collapse>
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Dashboard_Consolidated_view_of_Enterprise_highres}
                alt="Dashboard_Consolidated_view_of_Enterprise_highres"
                className="border-image"
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: "50px" }}></div>
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Configurable_fiscal_period_hires}
                alt="Configurablefiscalyear"
                className="border-image"
              />
            </div>
          </div>

          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h1>Customize your fiscals as per your financial period </h1>
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={Configurable_fiscal_period_hires}
                  alt="Analyze team’s performance and productivity accurately by configuring fiscal period settings"
                  className="border-image"
                />
              </div>
            </div>

            <p className="feature-left-box-content">
              Configure fiscal periods, quarters and more as per your needs.
              Dive deeper with our ou-of-the-box financial reporting and gain
              deatiled insights on progress and performance
              <div className="spacer"></div>
              Configure your business accounting period including fiscal
              quarters
              <br />
              <br />
              Save time, access QoQ and YoY reports, compare performance, with a
              single click
              <br />
              <br />
              Track real-time progress over oppertunities and manage your
              people, sales funnel at a granular level for better customer
              experiences.
            </p>
          </div>
        </div>

        <div className="feature-main-box_mobile ">
          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Configurable_fiscal_period_hires}
                alt="Analyze team’s performance and productivity accurately by configuring fiscal period settings"
                className="border-image"
              />
            </div>
          </div>

          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Configure Fiscal period & quarters, onboard in a minute </h2>
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img
                  src={Configurable_fiscal_period_hires}
                  alt="dasboard"
                  className="border-image"
                />
              </div>
            </div>

            <p className="feature-left-box-content">
              Configure your business accounting period including fiscal
              quarters. Access out of the box financial reporting and deeper
              insights on progress and performance – QoQ & YoY comparison, saves
              you time and effort.
              <div className="spacer"></div>
              <div onClick={toggle} style={{ color: "#2cc9ff" }}>
                {isOpen ? "Show Less" : "Show More"}
                {/* <CaretUp /> */}
              </div>
              <Collapse isOpen={isOpen}>
                Track real time progress and manage your sales funnel for the
                right results, monthly and weekly progress data is also
                available.
                <br />
                <br />
                Team leaders have access to performance and productivity data
                showcased from fiscal year perspective.
              </Collapse>
            </p>
          </div>
        </div>
        {/* /* left image box */}
        <div className="spacer-gap"></div>
        {/* /* right image box */}
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h1>Lead your business with real-time data and insights</h1>
            </div>

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={Dashboard_Consolidated_view_of_Enterprise_highres}
                  alt="Real time easy performance reporting and analysis for CEOs & Leaders"
                  className="border-image"
                />
              </div>
            </div>

            <p className="feature-left-box-content">
              Keep an eye on your entire company's sales performance in
              real-time in one place. Monitor your efforts and strategies with
              comprehensive dashboards to pave the right path for your sales
              team. Keep tabs on your business from your fingertips.
              <div className="spacer"></div>
              Access cpmpany's sales performance indicators in real time, all in
              one place
              <div className="spacer"></div>
              Richly visualized dashboards, across your sales processes, even
              past performance comparisions
              <div className="spacer"></div>
              Published in reporting currency as per demographics for Leaders to
              make informed decisionand guide sales team effectively
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Dashboard_Consolidated_view_of_Enterprise_highres}
                alt="Real time easy performance reporting and analysis for CEOs & Leaders"
                className="border-image"
              />
            </div>
          </div>
        </div>

        <div className="feature-main-box_mobile ">
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Real time performance analytics for CEOs & Leaders</h2>
            </div>

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img
                  src={Dashboard_Consolidated_view_of_Enterprise_highres}
                  alt="Real time easy performance reporting and analysis for CEOs & Leaders"
                  className="border-image"
                />
              </div>
            </div>

            <p className="feature-left-box-content">
              Access your organization’s sales performance indicators in real
              time and all in one place, single click of a button.
              <br />
              <br />
              <div onClick={toggle4} style={{ color: "#2cc9ff" }}>
                {isOpen ? "Show Less" : "Show More"}
                {/* <CaretUp /> */}
              </div>
              <Collapse isOpen4={isOpen}>
                Analyzed and presented in a richly visualized dashboard, summed
                across sales processes including past performance comparison.
                Reports and analytics published in reporting currency for
                Leaders to guide their sales teams.
              </Collapse>
              {/* <div className="spacer"></div> */}
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Dashboard_Consolidated_view_of_Enterprise_highres}
                alt="Dashboard_Consolidated_view_of_Enterprise_highres"
                className="border-image"
              />
            </div>
          </div>
        </div>
        {/* /* right image box */}
        <div className="spacer-gap"></div>
        {/* /* left image box */}
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Currency_hires}
                alt="Currency_hires"
                className="border-image"
              />
            </div>
          </div>

          <div
            className="feature-left-box
           "
          >
            <div className="feature-left-box-heading">
              <h1>Integrated Live Currency conversions and reportings</h1>
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={Currency_hires}
                  alt="With a geographically spread customer base, currency exchange supports your sales team in 
                creating commercials, managing quotations and ensuring governance across product library and services
                 listing. Sales executives can track their transactions in their local currency which allows them to manage 
                 their individual performances effectively. Leaders will have
                 access to organization wide data in a single reporting currency for easy reporting, analysis and more."
                  className="border-image"
                />
              </div>
            </div>
            <p className="feature-left-box-content">
              Easily manage and generate your reports and analytics for your
              geographically spread customer base, with a single click. Create
              quatations and commercials in multiple currencies for your
              customers in an instant. Tune your focus more on your
              oppertunities and closure, as for the adminstrative tasks leave it
              to us.
            </p>
          </div>
        </div>

        <div className="feature-main-box_mobile ">
          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Currency_hires}
                alt="With a geographically spread customer base, currency exchange supports your sales team in 
               creating commercials, managing quotations and ensuring governance across product library and services
                listing. Sales executives can track their transactions in their local currency which allows them to manage 
                their individual performances effectively. Leaders will have
                access to organization wide data in a single reporting currency for easy reporting, analysis and more."
                className="border-image"
              />
            </div>
          </div>

          <div
            className="feature-left-box
           "
          >
            <div className="feature-left-box-heading">
              <h2>Integrated Live Currency conversions and reportings</h2>
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img
                  src={Currency_hires}
                  alt="Currency_hires"
                  className="border-image"
                />
              </div>
            </div>
            <p className="feature-left-box-content">
              Easily manage and generate your reports and analytics for your
              geographically spread customer base, with a single click. Create
              quatations and commercials in multiple currencies for your
              customers in an instant. Tune your focus more on your
              oppertunities and closure, as for the adminstrative tasks leave it
              to us. <br />
              <br />
              <div onClick={toggle5} style={{ color: "#2cc9ff" }}>
                {isOpen5 ? "Show Less" : "Show More"}
                {/* <CaretUp /> */}
              </div>
              <Collapse isOpen={isOpen5}>
                Focus more on the opportunity and closure, leave the
                administrative tasks to Korero.
              </Collapse>
            </p>
          </div>
        </div>

        {/* /* left image box */}
        {/* First Box */}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {" "}
          <ScrollToTop />
        </div>
        <div className="feature-divider"></div>
        {/* Second Box */}
        <div className="feature1 " id="2">
          <h1> Customer journey management</h1>
        </div>
        <div className="feature-left-box-content1">
          Complex consumer decision making process involves identifying needs to
          finally conclude their buying decision.
        </div>
        <div className="main ">
          <div className="feature-right ">
            {/* <div className="image"> */}
            <img src={feature2} alt="feature2img" className="border-image" />
            {/* </div> */}
          </div>
          <div className="feature-left">
            <p className="feature-left-content">
              <br />
              <br />
              Categorize your customer base and employ unique strategy for
              regions / industry segments.
              <br />
              <br />
              Korero provides you have the flexibility to setup different
              processes to operationalize your strategy for better customer
              engagement and win more opportunities.
            </p>
          </div>
          {/* <div style={{marginTop:"50px"}}></div> */}
        </div>
        {/* /* right image box */}
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Multiple sales processes to support customer segments</h2>
            </div>

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={sales2}
                  alt="Korero presents a single view of your sales funnel
                 analytics by summing up the weighted values of all the 
                 opportunities, weighted funnel is calculated by multiplying
                  the proposal value with the weightage of each associated stage. "
                  className="border-image"
                />
              </div>
            </div>

            <p className="feature-left-box-content">
              Create multiple sales processes to manage different customer
              segments based on region, industry, size, etc and their decision
              making process. Populate each sales process with multiple
              milestones, configure tasks and approvals with their service level
              agreements.
              <div className="spacer"></div>
              Manage opportunity life cycle by tagging a sales process, progress
              across milestones with a simple drag and drop approach.
              <div className="spacer"></div>
              Rich insights including Sales Velocity, Weighted Funnel and
              analytics help you in focusing on the opportunity leading to
              improved win rate.
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={sales2}
                alt="Korero presents a single view of your sales funnel
                analytics by summing up the weighted values of all the 
                opportunities, weighted funnel is calculated by multiplying
                 the proposal value with the weightage of each associated stage."
                className="border-image"
              />
            </div>
          </div>
        </div>

        <div className="feature-main-box_mobile ">
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Multiple sales processes to support customer segments</h2>
            </div>

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img
                  src={sales2}
                  alt="Korero presents a single view of your sales funnel
                  analytics by summing up the weighted values of all the 
                  opportunities, weighted funnel is calculated by multiplying
                   the proposal value with the weightage of each associated stage."
                  className="border-image"
                />
              </div>
            </div>

            <p className="feature-left-box-content">
              Create multiple sales processes to manage different customer
              segments based on region, industry, size, etc and their decision
              making process. Populate each sales process with multiple
              milestones, configure tasks and approvals with their service level
              agreements.
              <div className="spacer"></div>
              <div onClick={toggle6} style={{ color: "#2cc9ff" }}>
                {isOpen1 ? "Show Less" : "Show More"}
                {/* <CaretUp /> */}
              </div>
              <Collapse isOpen={isOpen6}>
                Manage opportunity life cycle by tagging a sales process,
                progress across milestones with a simple drag and drop approach.
                <div className="spacer"></div>
                Rich insights including Sales Velocity, Weighted Funnel and
                analytics help you in focusing on the opportunity leading to
                improved win rate.
              </Collapse>
            </p>
          </div>
        </div>
        {/* /* right image box */}
        <div className="spacer-gap"></div>
        {/* /* left image box */}
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={SalesProcess_high_res}
                alt="SalesProcess_high_res"
                className="border-image"
              />
            </div>
          </div>

          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Workflows integrated with Sales processes</h2>
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={SalesProcess_high_res}
                  alt="SalesProcess_high_res"
                  className="border-image"
                />
              </div>
            </div>
            <p className="feature-left-box-content">
              Available in Professional subscription only, create unlimited
              sales processes, configure tasks and approvals, associate service
              level agreements for each activity for an accelerated opportunity
              management.
              <div className="spacer"></div>
              Include users from different functions other than sales, filter
              task executors and approvers based on function and job level.
              Rules are extremely easy to configure, complete in minutes.
              <div className="spacer"></div>
              Ensure each opportunity milestone is completed in a timely manner.
              Collaborate with team members and communicate with customer in
              time with the right inputs. Korero also allows for automation of
              approvals.
            </p>
          </div>
        </div>
        {/* /* left image box */}
        <div className="spacer-gap"></div>
        {/* /* right image box */}
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Task governance in today’s remote work environment</h2>
            </div>

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img src={img8} alt="img8" className="border-image" />
              </div>
            </div>
            <p className="feature-left-box-content">
              Configuration of Sales processes combined with workflows,
              automation and service level agreement allows completion of tasks
              and timely closure of opportunities.
              <div className="spacer"></div>
              Rules driven approach ensures distribution of tasks and approvals
              across multi-disciplinary teams. Korero supports collaboration
              between sales and other disciplines for tasks completion ensuring
              governance. Email automation enables sharing proposals and
              quotations with the respective contacts.
              <div className="spacer"></div>
              Korero presents simplified user interface for rules configuration,
              an enterprise does not need to engage an expert.
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img src={img8} alt="dasboard" className="border-image" />
            </div>
          </div>
        </div>

        <div className="feature-main-box_mobile ">
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Task governance in today’s remote work environment</h2>
            </div>

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img src={img8} alt="img8" className="border-image" />
              </div>
            </div>
            <p className="feature-left-box-content">
              Configuration of Sales processes combined with workflows,
              automation and service level agreement allows completion of tasks
              and timely closure of opportunities.
              <div className="spacer"></div>
              <div onClick={toggle7} style={{ color: "#2cc9ff" }}>
                {isOpen1 ? "Show Less" : "Show More"}
                {/* <CaretUp /> */}
              </div>
              <Collapse isOpen={isOpen7}>
                Rules driven approach ensures distribution of tasks and
                approvals across multi-disciplinary teams. Korero supports
                collaboration between sales and other disciplines for tasks
                completion ensuring governance. Email automation enables sharing
                proposals and quotations with the respective contacts.
                <div className="spacer"></div>
                Korero presents simplified user interface for rules
                configuration, an enterprise does not need to engage an expert.
              </Collapse>
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img src={img8} alt="dasboard" className="border-image" />
            </div>
          </div>
        </div>
        {/* /* right image box */}

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {" "}
          <ScrollToTop />
        </div>
        <div className="feature-divider"></div>
        {/* Third Box */}
        <div className="feature1" id="3">
          <h1>Automation adds value to business </h1>
        </div>
        <div className="main ">
          <div className="feature-right ">
            {/* <div className="image"> */}
            <img
              src={feature3}
              alt="featur3bgimg
            "
              className="border-image"
            />
            {/* </div> */}
          </div>
          <div className="feature-left">
            <div className="spacer"></div>
            {/* <div className="spacer"></div> */}
            <p className="feature-left-content">
              Easily configure processes using rules and automate the processes
              to manage Approvals and Escalations. Also configure and automate
              e-mails and notifications so that users are updated in time.
              <div className="spacer"></div>
              Simple UI allows for defining the rules easily, create and tag
              templates to customize notifications and email messages to improve
              communication.
            </p>
          </div>
        </div>
        <div className="feature-main-box ">
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">Leads management</div>

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img src={leads} alt="leads" />
              </div>
            </div>

            <p className="feature-left-box-content">
              Leads can be easily automatically distributed with rules
              configured in the automation section. Leads captured from your
              website can be pushed into a sales user’s leads funnel, can also
              be distributed in a round robin format across selected sales
              users.
              <div className="spacer"></div>
              Admin can configure rules for ageing, users will be notified of
              leads which are under scanner. Unqualified aged leads will be
              classified as Junk.
              <div className="spacer"></div>
              Admin has access to Junk leads and can redistribute them amongst
              sales users as needed.
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img src={leads} alt="leads" />
            </div>
          </div>
        </div>

        <div className="feature-main-box_mobile ">
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Leads management</h2>
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img src={leads} alt="leads" className="border-image" />
              </div>
            </div>
            <p className="feature-left-box-content">
              Leads can be easily automatically distributed with rules
              configured in the automation section. Leads captured from your
              website can be pushed into a sales user’s leads funnel, can also
              be distributed in a round robin format across selected sales
              users.
              <div className="spacer"></div>
              <div onClick={toggle8} style={{ color: "#2cc9ff" }}>
                {isOpen1 ? "Show Less" : "Show More"}
                {/* <CaretUp /> */}
              </div>
              <Collapse isOpen={isOpen8}>
                Admin can configure rules for ageing, users will be notified of
                leads which are under scanner. Unqualified aged leads will be
                classified as Junk.
                <div className="spacer"></div>
                Admin has access to Junk leads and can redistribute them amongst
                sales users as needed.
              </Collapse>
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img src={leads} alt="leads" className="border-image" />
            </div>
          </div>
        </div>

        <div style={{ marginTop: "50px" }}></div>
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-right-box ">
            <div className="feature-image">
              <img src={quotation} alt="quotation" />
            </div>
          </div>

          <div className="feature-left-box ">
            <div className="feature-left-box-heading">Quotations</div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img src={quotation} alt="quotation" />
              </div>
            </div>

            <p className="feature-left-box-content">
              Create Quotes using Templates, configure rules for sending quotes
              to internal and external stakeholders. Automate sending emails and
              notifications.
            </p>
          </div>
        </div>

        <div className="feature-main-box_mobile ">
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Quotations</h2>
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img src={quotation} alt="quotation" className="border-image" />
              </div>
            </div>
            <p className="feature-left-box-content">
              Create Quotes using Templates, configure rules for sending quotes
              to internal and external stakeholders. Automate sending emails and
              notifications.
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img src={quotation} alt="quotation" className="border-image" />
            </div>
          </div>
        </div>
        <div style={{ marginTop: "50px" }}></div>
        {/* /* right image box */}

        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Approvals</h2>
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={Approvals_hires}
                  alt="Approvals_hires"
                  className="border-image"
                />
              </div>
            </div>
            <p className="feature-left-box-content">
              Approval process can be easily configured through rules using
              Korero’s simple User Interface. Sales users can simply assign from
              the list of filtered users.
              <div className="spacer"></div>
              Approver has access to complete task history, documents and
              information for a faster turn-around, close opportunities faster.
              <div className="spacer"></div>
              Korero allows creation of multiple Templates for emails and
              notifications.
              <div className="spacer"></div>
              Approvals can also be automated depending on proposal value,
              threshold values can be defined by the admin with notifications to
              the stakeholders.
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Approvals_hires}
                alt="Approvals_hires"
                className="border-image"
              />
            </div>
          </div>
        </div>

        <div className="feature-main-box_mobile ">
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Approvals</h2>
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img
                  src={Approvals_hires}
                  alt="Approvals_hires"
                  className="border-image"
                />
              </div>
            </div>
            <p className="feature-left-box-content">
              Approval process can be easily defined through rules using
              Korero’s simple User Interface and assigned by the opportunity
              owner by simply selecting from the list of selected users.
              <div className="spacer"></div>
              Approval provider has access to complete task history, associated
              documents and more that enables faster turn around leading to
              closure of opportunities.
              <div className="spacer"></div>
              Approvals can be automated as per rules and ensure that the
              important ones are addressed.
              <div className="spacer"></div>
              Korero allows to create multiple Templates for communication
              through emails and notifications.
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Approvals_hires}
                alt="Approvals_hires"
                className="border-image"
              />
            </div>
          </div>
        </div>
        {/* /* right image box */}
        <div className="spacer-gap"></div>
        {/* /* left image box */}
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Escalation_hires}
                alt="Escalation_hires"
                className="border-image"
              />
            </div>
          </div>

          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Escalation</h2>
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={Escalation_hires}
                  alt="Escalation_hires"
                  className="border-image"
                />
              </div>
            </div>
            <p className="feature-left-box-content">
              The admin of your organization will be able to define rules for
              escalation that may arise due to non conformance of tasks and
              activities.
              <div className="spacer"></div> Rules can be easily defined through
              simple UI which finally ensures that your opportunity is serviced
              in time.
            </p>
          </div>
        </div>

        <div className="feature-main-box_mobile ">
          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Escalation_hires}
                alt="Escalation_hires"
                className="border-image"
              />
            </div>
          </div>

          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Escalation</h2>
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img
                  src={Escalation_hires}
                  alt="Escalation_hires"
                  className="border-image"
                />
              </div>
            </div>
            <p className="feature-left-box-content">
              The admin of your organization will be able to define rules for
              escalation that may arise due to non conformance of tasks and
              activities.
              <div className="spacer"></div> Rules can be easily defined through
              simple UI which finally ensures that your opportunity is serviced
              in time.
            </p>
          </div>
        </div>
        {/* /* left image box */}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {" "}
          <ScrollToTop />
        </div>
        <div className="feature-divider"></div>
        {/* Fourth Box */}
        <div className="feature1" id="4">
          <h1>Leads, Opportunities and Closures</h1>
        </div>
        <div className="main ">
          <div className="feature-right ">
            {/* <div className="image"> */}
            <img src={feature5} alt="fearureing7jfg" className="border-image" />
            {/* </div> */}
          </div>
          <div className="feature-left">
            <div className="spacer"></div>
            {/* <div className="spacer"></div> */}
            <p className="feature-left-content">
              Key data and analytics regarding customer and opportunity
              presented in a rich UI for better customer engagement.
              <br />
              <br />
              Perspectives, auto leads capture and tile view for better User
              experience
            </p>
          </div>
        </div>
        {/* /* right image box */}
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Perspectives view</h2>
            </div>

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={OpportunityPerspectives_hires}
                  alt="OpportunityPerspectives_hires"
                  className="border-image"
                />
              </div>
            </div>

            <p className="feature-left-box-content">
              Korero presents you customer, opportunities and related data in
              Perspectives that allows you to analyze each pursuit and
              associated entities for strategizing your next steps leading to
              increased closure rates.
              <div className="spacer"></div>Categorized and populated in themes,
              each tile presents meaningful analytics.
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={OpportunityPerspectives_hires}
                alt="OpportunityPerspectives_hires"
                className="border-image"
              />
            </div>
          </div>
        </div>

        <div className="feature-main-box_mobile ">
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Perspectives view</h2>
            </div>

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img
                  src={OpportunityPerspectives_hires}
                  alt="OpportunityPerspectives_hires"
                  className="border-image"
                />
              </div>
            </div>

            <p className="feature-left-box-content">
              Korero presents you customer, opportunities and related data in
              Perspectives that allows you to analyze each pursuit and
              associated entities for strategizing your next steps leading to
              increased closure rates.
              <div className="spacer"></div>Categorized and populated in themes,
              each tile presents meaningful analytics.
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={OpportunityPerspectives_hires}
                alt="OpportunityPerspectives_hires"
                className="border-image"
              />
            </div>
          </div>
        </div>
        {/* /* right image box */}
        <div className="spacer-gap"></div>
        {/* /* left image box */}
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={CustomerMap_highres}
                alt="Configurablefiscalyear"
                className="border-image"
              />
            </div>
          </div>

          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Map your Customer</h2>
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={CustomerMap_highres}
                  alt="Configurablefiscalyear"
                  className="border-image"
                />
              </div>
            </div>
            <p className="feature-left-box-content">
              Visualization on maps coupled with analytics helps coordinate
              sales outreach efforts.
            </p>
          </div>
        </div>

        <div className="feature-main-box_mobile ">
          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={CustomerMap_highres}
                alt="Configurablefiscalyear"
                className="border-image"
              />
            </div>
          </div>

          <div className="feature-left-box ">
            <div className="feature-left-box-heading">Map your Customer</div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img
                  src={CustomerMap_highres}
                  alt="CustomerMap_highres"
                  className="border-image"
                />
              </div>
            </div>
            <p className="feature-left-box-content">
              Visualization on maps coupled with analytics helps coordinate
              sales outreach efforts.
            </p>
          </div>
        </div>
        {/* /* left image box */}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {" "}
          <ScrollToTop />
        </div>
        <div className="feature-divider"></div>
        {/* Fifth Box */}
        <div className="feature1" id="5">
          <h1>Collaboration – Break the silos</h1>
        </div>
        <div className="main ">
          <div className="feature-right ">
            {/* <div className="image"> */}
            <img src={feature4} alt="dfsgdfg" className="border-image" />
            {/* </div> */}
          </div>
          <div className="feature-left">
            <div className="spacer"></div>
            {/* <div className="spacer"></div> */}
            <p className="feature-left-content">
              Accelerate opportunity closures, include users from non-sales
              functions to help complete tasks associated with opportunities.
              Also collaborate with your alliance partners by extending Korero
              accounts and share data securely.
              <br />
              <br />
              Include users from non-sales functions to accelerate opportunity
              closures. Also collaborate with your partners by extending access
              to Korero and share data securely.
            </p>
          </div>
        </div>
        {/*p/* right image box */}
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              {" "}
              <h2>Interdisciplinary co-operation </h2>
            </div>

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={Custom_Function_hires}
                  alt="Custom_Function_hires"
                  className="border-image"
                />
              </div>
            </div>

            <p className="feature-left-box-content">
              You have the flexibility to include colleagues from disciplines
              other than sales functions. Create your own customized function
              and add users. Role based access allows users of other disciplines
              to support sales team on key tasks, approvals and more.
              <div className="spacer"></div>
              Faster turn-around times, high quality proposals and quotes lead
              to improve win rate.
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Custom_Function_hires}
                alt="Custom_Function_hires"
                className="border-image"
              />
            </div>
          </div>
        </div>

        <div className="feature-main-box_mobile ">
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              {" "}
              Interdisciplinary co-operation{" "}
            </div>

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img
                  src={Custom_Function_hires}
                  alt="img7"
                  className="border-image"
                />
              </div>
            </div>

            <p className="feature-left-box-content">
              You have the flexibility to include colleagues from disciplines
              other than sales functions. Create your own customized function
              and add users. Role based access allows users of other disciplines
              to support sales team on key tasks, approvals and more.
              <div className="spacer"></div>
              Faster turn-around times, high quality proposals and quotes lead
              to improve win rate.
            </p>
          </div>

          {/* <div className="feature-right-box ">
            <div className="feature-image">
              <img src={img6} alt="dasboard" className="border-image" />
            </div>
          </div> */}
        </div>
        {/* /* right image box */}
        <div className="spacer-gap"></div>
        {/* /* left image box */}
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Partner_Access_hires}
                alt="Partner_Access_hires"
                className="border-image"
              />
            </div>
          </div>

          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2> Collaboration beyond Enterprise</h2>
            </div>

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={Partner_Access_hires}
                  alt="Partner_Access_hires"
                  className="border-image"
                />
              </div>
            </div>
            <p className="feature-left-box-content">
              Korero allows you to invite 3rd party stakeholders from partner
              organizations to collaborate on an opportunity, create and upload
              assets like documents, sharing insights about opportunity
              sponsors, managing transactions like calls and meetings and more.
              <div className="spacer"></div>
              Access for partner login through an Approval process driven
              through Workflow ensures governance. Partner logins can be
              auto-closed according to rules created by your admin. Secure
              platform ensures seamless communication in real time.
              <div className="spacer"></div>
              All artifacts created by the partner will be transferred to the
              opportunity owner on closure of partner account ensuring data is
              not lost.
            </p>
          </div>
        </div>

        <div className="feature-main-box_mobile ">
          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={Partner_Access_hires}
                alt="img7"
                className="border-image"
              />
            </div>
          </div>

          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2> Collaboration beyond Enterprise</h2>
            </div>

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={Partner_Access_hires}
                  alt="Partner_Access_hires"
                  className="border-image"
                />
              </div>
            </div>
            <p className="feature-left-box-content">
              Korero allows you to invite 3rd party stakeholders from partner
              organizations to collaborate on an opportunity, create and upload
              assets like documents, sharing insights about opportunity
              sponsors, managing transactions like calls and meetings and more.
              <div className="spacer"></div>
              Access for partner login through an Approval process driven
              through Workflow ensures governance. Partner logins can be
              auto-closed according to rules created by your admin. Secure
              platform ensures seamless communication in real time.
              <div className="spacer"></div>
              All artifacts created by the partner will be transferred to the
              opportunity owner on closure of partner account ensuring data is
              not lost.
            </p>
          </div>
        </div>
        {/* /* left image box */}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {" "}
          <ScrollToTop />
        </div>
        {/* <div className="feature-divider"></div> */}
        {/* <div className="feature1" id="6">
          <h1>Security and Access</h1>
        </div> */}
        <div className="main ">
          <div className="feature-right ">
            {/* <div className="image"> */}
            <img src={feature6} alt="image467" className="border-image" />
            {/* </div> */}
          </div>
          <div className="feature-left">
            <div className="spacer"></div>
            {/* <div className="spacer"></div> */}
            <p className="feature-left-content">
              Deployed on AWS cloud, Korero has number of features to ensure
              safety of your data. Built for omni-channel access 24/7, Korero is
              laptop and tablet friendly, mobile app to be released shortly.
            </p>
          </div>
        </div>

        {/* /* right image box */}
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              {" "}
              <h2>Security</h2>
            </div>

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image">
                <img
                  src={security2}
                  alt="security2"
                  height="150vh"
                  className="border-image"
                />
              </div>
            </div>

            <p className="feature-left-box-content">
              Korero is deployed on AWS (Paris location) that ensures high level
              of security, we have included password encryption and tokenized
              access to ensure that your data stays protected 24/7.
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={security2}
                alt="security2"
                height="250vh"
                className="border-image"
              />
            </div>
          </div>
        </div>

        <div className="feature-main-box_mobile ">
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              {" "}
              <h2>Security</h2>
            </div>

            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img
                  src={security2}
                  alt="security2"
                  height="150vh"
                  className="border-image"
                />
              </div>
            </div>

            <p className="feature-left-box-content">
              Korero is deployed on AWS (Paris location) that ensures high level
              of security, we have included password encryption and tokenized
              access to ensure that your data stays protected 24/7.
            </p>
          </div>

          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={security2}
                alt="security2"
                height="250vh"
                className="border-image"
              />
            </div>
          </div>
        </div>
        {/* /* right image box */}
        <div className="spacer-gap"></div>
        {/* /* left image box */}
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="wrapping">
            <div className="feature-right-box ">
              <div className="feature-image">
                <img
                  src={Dashboard_highres}
                  alt="mobile"
                  height="250vh"
                  className="border-image"
                />
              </div>
            </div>
            <div className="feature-right-box ">
              <div className="feature-image">
                <img
                  src={Contact_Mobile_highres}
                  alt="mobile"
                  height="250vh"
                  className="border-image"
                />
              </div>
            </div>
            <div className="feature-right-box ">
              <div className="feature-image">
                <img
                  src={Event_Add_highres}
                  alt="mobile"
                  height="250vh"
                  className="border-image"
                />
              </div>
            </div>
          </div>
          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Access</h2>
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="wrapping">
                <div className="feature-right-box ">
                  <div className="feature-image">
                    <img
                      src={Dashboard_highres}
                      alt="mobile"
                      height="250vh"
                      className="border-image"
                    />
                  </div>
                </div>
                <div className="feature-right-box ">
                  <div className="feature-image">
                    <img
                      src={Contact_Mobile_highres}
                      alt="mobile"
                      height="250vh"
                      className="border-image"
                    />
                  </div>
                </div>
                <div className="feature-right-box ">
                  <div className="feature-image">
                    <img
                      src={Event_Add_highres}
                      alt="mobile"
                      height="250vh"
                      className="border-image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <p className="feature-left-box-content">
              Currently Korero desktop, laptop and tablet friendly, accessible
              24/7 online. Korero’s mobile app is available on Google Play
              store, we aim to achieve omni channel presence by Jan 2021 with
              app also rolled out on Apple store.
            </p>
          </div>
        </div>

        <div className="feature-main-box_mobile ">
          <div className="feature-right-box ">
            <div className="feature-image">
              {/* <img
                src={mobile}
                alt="mobile"
                height="250vh"
                className="border-image"
              /> */}
            </div>
          </div>

          <div className="feature-left-box ">
            <div className="feature-left-box-heading">
              <h2>Access</h2>
            </div>
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image-mobile">
                <img
                  src={Dashboard_highres}
                  alt="mobile"
                  height="150vh"
                  className="border-image"
                />
                <img
                  src={Contact_Mobile_highres}
                  alt="mobile"
                  height="150vh"
                  className="border-image"
                />
                {/* <img
                src={Event_Add_highres}
                alt="mobile"
                height="150vh"
                className="border-image"
              /> */}
              </div>
            </div>
            <p className="feature-left-box-content">
              Currently Korero desktop, laptop and tablet friendly, accessible
              24/7 online. Korero’s mobile app is available on Google Play
              store, we aim to achieve omni channel presence by Jan 2021 with
              app also rolled out on Apple store.
            </p>
          </div>
        </div>
        {/* /* left image box */}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {" "}
          <ScrollToTop />
        </div>
        <div className="mobile_gap"></div>
      </div>
      <div className="feature-container-3">
        <Signup />
      </div>
    </div>
  )
}

export default Homepage
