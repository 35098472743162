import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Features from "../modules/Features/Features"
// import { Helmet } from "react-helmet"

const SecondPage = () => (
  <Layout>
    <SEO title="Features" 
    description="Korero is designed to be your single platform for customer management, communication and
    collaboration. An out of the box, cloud based, SaaS solution using data driven approach Korero ensures 
    improved customer engagement and higher win rates. Easily configurable, simple UI and automation ensures higher productivity."
    />
    <Features />
    {/* <Helmet> */}
    <script src="https://platform.linkedin.com/in.js" type="text/javascript">
      {" "}
      lang: en_US
    </script>
    {/* </Helmet> */}
  </Layout>
)

export default SecondPage
